.header {
    h1 {
        font-size: 2.7rem;
    }        

    p {
        color: var(--gray-900);
        margin-top: 1rem;
        font-size: 1.1rem;
    }   // p
}   // .header

@media(max-width: 479px) {
    .header {

        h1 {
            text-align: center;
            font-size: 2.3rem;
            line-height: 2.8rem;
        }

        p {
            text-align: center;
            margin-top: 1.5rem;
        }
    }
}