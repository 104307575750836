.formSiteInfo {      
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .inputDiv {     
        display: flex;
        flex-direction: column;
        margin-top: .5rem;

        .inputWithAppend {
            display: flex;
            align-items: center;

            .append {
                background-color: var(--gray-100);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4rem;
                height: 2.4rem;
                margin-top: .5rem;
                border-top-left-radius: .2rem;
                border-bottom-left-radius: .2rem;

                span {
                    font-weight: bold;
                    color: var(--text);
                }
            }

            input {
                width: 11rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        label {     
            color: var(--white);
            font-weight: bold;
        }

        input {     
            background-color: var(--white);
            color: var(--text);
            border: 1px solid var(--white);
            width: 15rem;

            &:focus {
                border-color: blue;
            }

            &::placeholder {
                color: var(--gray-200);
            }

            &:nth-child(n+2) {
                margin-top: .5rem;
            }
        }   // input
    }   // .inputDiv 

    .inputCheckbox {        
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 250ms;
        margin-top: .8rem;
        height: 2.4rem;
        border-radius: .3rem;
        width: 9rem;

        &:hover {
            filter: brightness(0.9);
        }

        .box {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 250ms;
            width: 1rem;
            height: 1rem;
            border-radius: .2rem;
        }   

        span {
            color: var(--white);
            font-weight: bold;
            transition: 250ms;
            margin-left: .7rem;
            font-size: 1rem;
        }   // span
    }   // .inputCheckbox
}   // .formSiteInfo

.formUserInfo {          
    display: flex;
    flex-direction: column;

    .inputDiv {     
        display: flex;
        flex-direction: column;

        &:nth-child(n + 2) {
            margin-top: .7rem;
        }
        
        label {     
            color: var(--white);
        }

        input {     
            background-color: var(--white);
            margin-top: .3rem;
            width: 100%;

            &:focus {
                border-color: var(--white);
            }
        }
    }

    .terms {        
        width: 18rem;
        margin: .5rem 0 0 -.6rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {      
            color: var(--white);
            margin-top: .5rem;
            line-height: 1.2rem;
        } // span
    }   // .terms
}   // .formUserInfo

.formAddNewSite {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 1rem;
    transition: 500ms;

    .inputDiv {
        display: flex;
        flex-direction: column;
        width: 100%;

        &:nth-child(n + 2) {
            margin-top: .7rem;
        }

        .inputWithAppend {
            display: flex;
            align-items: items;

            .append {
                background-color: var(--gray-100);
                display: flex;
                align-items: center;
                justify-content: center;
                height: calc(2.3rem + 1px);
                width: 4rem;
                margin-top: .5rem;
                border-top-left-radius: .2rem;
                border-bottom-left-radius: .2rem;

                span {
                    font-weight: bold;
                    color: var(--text);
                }
            }

            input {
                width: 100%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin-top: .5rem;
            }
        }   // .inputDiv

        input {
            width: 100%;
            margin-top: .3rem;
        }
    }

    .addSiteButton {
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
    }   // .addSiteButton
}   // .formAddNewSite

.editForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 1rem;

    .inputDiv {
        margin-top: 0;

        .input {
            width: 100%;
            margin-top: .4rem;
        }

        .fileInput {
            margin-top: .4rem;
            padding: 0;
            border: none;
        }   // .fileInput
    }   // .inputDiv
}   //.editForm

.formAdmanager {
    margin-top: 1rem;

    .inputDiv {
        display: flex;
        flex-direction: column;

        &:last-child {
            margin-top: 1rem;
        }

        input {
            margin-top: .5rem;
            width: 16rem;
        }   // input
    }   // .inputDiv
}   // .formAdmanager

.formPainelAccount {
    margin-top: 1rem;

    .inputDiv {
        display: flex;
        flex-direction: column;

        &:nth-child(n + 2) {
            margin-top: 1rem;
        }

        .wrapInput {
            display: flex;
            align-items: center;
            border: 1px solid var(--gray-100);
            margin-top: .5rem;
            width: 12rem;
            border-radius: .2rem;

            input {
                border: 1px solid transparent;
                width: 100%;
                
                &:focus {
                    border-color: rgb(150, 150, 150);
                }
            }

            .passwordButton {
                background-color: var(--white);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: 250ms;
                padding: 0 .8rem;
                height: 2.4rem;
                border-top-right-radius: .2rem;
                border-bottom-right-radius: .2rem;
                border-left: 1px solid var(--gray-100);

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }
    }   // .inputDiv
}   // form

// Label que fica no lugar do input type file para ficar mais bonito visualmente
.labelForFile {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: regular;
    border: 1px dashed var(--gray-200);
    transition: 250ms;
    height: 10rem;
    border-radius: .3rem;
    margin-top: .5rem;

    &:hover {
        border-color: var(--black);
    }
}

.fileInput {
    display: none;
}

.chooseColor {
    display: flex;
    align-items: flex-end;

    .wrapColor {
        overflow: hidden;
        margin-top: 1rem;
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        box-shadow: 0 .050rem .3rem;

        .color {
            background-color: transparent;
            cursor: pointer;
            transition: 250ms;
            padding: 0;
            border: 0;
            width: 200%;
            height: 200%;
            border-radius: 40px;
            transform: translate(-25%, -25%);

            &:hover {
                background-color: var(--black);
            }
        }
    }

    .columnColor {
        display: flex;
        flex-direction: column;
        margin: 1rem 0 0 1rem;

        input {
            width: 9rem;
            margin-top: .5rem;
        }
    }
}


/** ---------------------- RESPONSIVIDADE MAX-WIDTH ---------------------- **/
@media (max-width: 479px) {
    .formAddNewSite {
        margin-top: 0;

        .inputDiv {
            input {
                width: 100%;
            }
        }

        .inlineInput {
            width: 100%;

            .inputDiv {
                span {
                    width: 50%;
                }
            }
        }

        .checkboxArea {
            margin: 2rem 0 0 -.6rem;

            span {
                width: 100%;
            }   // span
        }   // .checkboxArea
    }   // formAddNewSite

    .editForm {
        grid-template-columns: 1fr;
        width: 100%;
    }   // .editForm
    
    .formAdmanager {
        margin-top: 2rem;
        
        .inputDiv {
            label {
                font-size: 1.2rem;
            }

            input {
                width: 100%;
            }   // input
        }   // .inputDiv
    }   // .formAdmanager
}

@media(max-width: 635px) {
    .formSiteInfo {
        margin: 2rem 0;
        width: 100%;

        .inputDiv {
            &:nth-child(n + 2) {
                margin-top: 1rem;
            }

            .inputWithAppend {
                input {
                    width: 100%;
                }
            }

            input {
                width: 100%;
            }   // input
        }   // .inputDiv  
    }   // .formSiteInfo

    .formUserInfo {
        width: 100%;
        margin: 2rem 0 0 0;

        .submitButton {
            display: flex;
            justify-content: flex-end;
        }   // .submitButton
    }   // .formUserInfo
}

@media (max-width: 879px) {
    .formAddNewSite {
        width: 100%;

        .inputDiv {
            input {
                width: 100%;
            }

            .inputWithAppend {
                .append {
                    width: 5rem;
                }

                input {
                    width: 100%;
                }   // input
            }   // .inputWithAppend
        }   // .inputDiv
    }   // .formAddNewSite

    .formAdmanager {
        .inputDiv {
            .wrapInput {
                width: 100%;
            }

            label {
                font-size: 1rem;
            }

            input {
                width: 100%;
            }   // .input
        }   // .inputDiv
    }   // .formAdmanager
}
/** ----------------------- FIM MAX-WIDTH ------------------------------- **/
