.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 4rem);

    .innerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 500ms;
        
        h1 {
            font-size: 4rem;
            margin-top: .7rem;
        }
    
        p {
            text-align: center;
            margin-top: 1.4rem;
            width: 70%;
            font-size: 1.3rem;
        }
    }
}

@media (max-width: 640px) {
    .container {
        .innerContainer {
            p {
                width: 80%;
            }
        }
    }
}