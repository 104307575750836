$font: "Roboto", monospace;
$steps: 50;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;

    h1 {
        font-size: 3.5rem;
        outline: none;
        text-align: center;
        font-family: $font;
        
        &,
        * {
            animation: cray 6s infinite steps($steps);
            display: inline-block;
        }
    }   // h1

    p {
        color: var(--gray-900);
        text-align: center;
        font-size: 1.3rem;
        width: 40rem;
        margin-top: .5rem;
    }
}   // .container

@keyframes cray {
    @for $i from 1 to $steps {
        $per: 100% / $steps * $i;
        $weight: (
            100,
            200,
            300,
            400,
            500,
            600,
            700
        );
        $style: (
            normal,
            italic,
            normal,
            normal
        );
        $decor: (
            none,
            none,
            line-through,
            underline,
            none,
            none,
            none
        );
        $trans: (
            none,
            none,
            uppercase,
            lowercase,
            capitalize,
            none,
            none,
            none,
            none,
            none,
            none
        );

        $arrayW: random(7);
        $arrayS: random(4);
        $arrayD: random(7);
        $arrayT: random(11);

        #{$per} {
            font-weight: nth($weight, $arrayW);
            font-style: nth($style, $arrayS);
            text-decoration: nth($decor, $arrayD);
            text-transform: nth($trans, $arrayT);
        }
    }
}

// Se o usuário tiver marcado para não ver animações no Windows
@media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
      animation-delay: -1ms !important;
      animation-duration: 1ms !important;
      animation-iteration-count: 1 !important;
      background-attachment: initial !important;
      scroll-behavior: auto !important;
      transition-duration: 0s !important;
      transition-delay: 0s !important;
    }
}

@media(max-width: 800px) {
    .container {
        h1 {
            font-size: 2.5rem;
            line-height: 3rem;
        }

        p {
            width: 90%;
        }
    }
}