.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3rem;
    height: 4rem;

    .logoDiv {
        cursor: pointer;

        .logo {
            width: 100px;
            height: 32px;
        }
    }

    .haveAnAccount {
        span {
            color: var(--black);
            cursor: pointer;
            font-size: 1rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}   // .header

/** ---------------------- RESPONSIVIDADE MIN-WIDTH ---------------------- **/
@media(min-width: 1700px) {
    .header {
        padding: 0 15rem;
    }
}

@media(min-width: 2000px) {
    .header {
        padding: 0 20rem;
    }
}

@media(min-width: 2200px) {
    .header {
        padding: 0 25rem;
    }
}

@media(min-width: 2500px) {
    .header {
        padding: 0 30rem;
    }
}

@media (max-width: 880px) {
    .header {
        padding: 1rem;
    }
}