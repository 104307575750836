.container {   
    .about {        
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;

        h1 {    
            font-size: 2.2rem;
        }   

        .aboutInline {  
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 3rem 0;

            .aboutItem {        
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;

                &:nth-child(n + 2) {
                    border-left: 1px solid var(--gray-200);
                }

                h2 {            
                    color: var(--black);
                    font-size: 2rem;
                }

                span {          
                    font-size: 1.5rem;
                    color: var(--gray-900);
                }   // span
            }   // .aboutItem
        }   //  .aboutInline
    }   // .about

    .content {     
        display: flex;
        align-items: center;
        padding: 2rem 3rem;
        background: linear-gradient(20deg, #9358F7 0.87%, #9259F7 7.31%, #8E5DF6 13.75%, #8862F5 20.19%, #806BF4 26.63%, #7575F2 33.07%, #6882F0 39.51%, #5990EE 45.95%, #4A9FEB 52.39%, #3BADE9 58.84%, #2EBAE7 65.28%, #23C4E5 71.72%, #1BCDE4 78.16%, #15D2E3 84.6%, #11D6E2 91.04%, #10D7E2 97.48%);
        
        .descriptiveTextArea {  
            width: 50%;

            h2 {        
                color: var(--white);
                font-size: 2rem;
            }

            p {
                color: var(--white);
                line-height: 1.7rem;
                font-size: 1.2rem;
                margin-top: 1rem;
            }       
        }   // .descriptiveTextArea
    }

    .stepsArea {    
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3rem 0;
        padding: 0 3rem;

        .stepsItem {        
            display: flex;
            align-items: center;

            &:nth-child(n + 2) {
                margin-left: 2rem;
            }

            .stepsBall {    
                background-color: var(--gray-100);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4rem;
                height: 4rem;
                border-radius: 2rem;

                span {      
                    color: var(--primary);
                    font-weight: bold;
                    font-size: 2.5rem;
                }
            }

            .text {         
                font-size: 1.3rem;
                margin-left: .7rem;
            }   // .text
        }   // .stepsItems
    }   // .stepsArea

    .examples {     
        margin-top: 4rem;
        padding: 3rem;

        .sliderArea {       
            .sliderInline {     
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 2rem;
    
                .sliderItem {       
                    display: flex;
                    align-items: center;

                    .wrapInput {        
                        display: flex;
                        align-items: center;
                        font-size: 2rem;
                        padding: .3rem;
                        border: 1px solid var(--gray-100);
                        border-radius: .3rem;

                        input {         
                            text-align: center;
                            border: none;
                            font-size: 2rem;
                            width: 3.2rem;
                            padding: 0;
                        }
                    }   // .wrapInput
    
                    .sliderVerticalItem {           
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;

                        span {                      
                            font-weight: bold;
                            font-size: 1.3rem;
                        }
                    }   // .sliderVerticalItem
                }   // .sliderItem
            }   // .sliderInline
        }   // .sliderArea

        .exampleArea {      
            margin-top: 2rem;

            .exampleContainer {     
                &:last-child {
                    margin-top: 3rem;
                }

                .exampleGrid {      
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
    
                    .exampleItem {      
                        transition: 250ms;
                        border: 1px solid var(--gray-100);
                        border-radius: .5rem;
    
                        &:nth-child(n + 2) {
                            margin-left: 3rem;
                        }
    
                        .exampleHeader {    
                            display: flex;
                            align-items: center;
                            margin: 1rem 0 0 .5rem;
                            width: 12rem;
    
                            span {         
                                cursor: pointer;
                                font-size: .9rem;
                            }
    
                            .showMoreButton {       
                                background-color: var(--primary);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-decoration: none;
                                cursor: pointer;
                                transition: 250ms;
                                width: 2rem;
                                height: 2rem;
                                margin: 0 1rem 0 auto;
                                border-radius: .3rem;
    
                                &:hover {
                                    filter: brightness(0.9);
                                }
                            }   // .showMoreButton
                        }   // .exampleHeader
    
                        .imgArea {       
                            margin-top: .5rem;
                            cursor: pointer;

                            img {       
                                margin: 0 1rem;
                                width: 126px;
                                height: 138px;
                            }

                            &:nth-child(5n) {
                                margin-left: 100px;
                            }
                        }
    
                        .exampleFooter {        
                            display: flex;
                            flex-direction: column;
                            cursor: pointer;
                            transition: 250ms;
                            border-top: 1px solid var(--gray-100);
                            padding: .7rem 1rem;
                            margin-top: .5rem;
    
                            span {              
                                &:first-child {
                                    color: var(--gray-900);
                                }
    
                                &:last-child {
                                    color: var(--text);
                                    font-size: 1.1rem;
                                }
                            }   // span
                        }   // .exampleFooter
                    }   // .exampleItem
                }   // .exampleGrid
            }   // .exampleContainer
        }   // .exampleArea
    }   // .examples

    .receitaArea {      
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        padding: 0 3rem;

        .receitaValueArea {     
            width: 45%;

            span {              
                color: var(--gray-900);
                font-size: 1.2rem;
            }

            .inlineValue {      
                display: flex;
                align-items: center;

                span {          
                    color: var(--text);

                    &:first-child {
                        font-size: 2rem;
                    }

                    &:last-child {
                        margin-left: .6rem;
                    }
                } // span
            }   // .receitaValueArea
        }   // .receitaValueArea

        .lineSeparator {        
            background-color: var(--gray-100);
            width: .1rem;
            height: 10rem;
            margin-right: 5rem;
        }

        p {                      
            width: 45%;
        } // p
    }   // .receitaArea

    .questionsArea {
        background-color: var(--primary);
        padding: 2rem 3rem;
        margin-top: 2rem;

        h2 {
            color: var(--white);
            font-size: 2rem;
        }

        .questionsInline {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            margin-top: 1.4rem;

            .questionsItem {
                background-color: var(--white);
                cursor: pointer;
                transition: 250ms;
                padding: 0 1rem;
                border-radius: .4rem;

                .questionsHeader {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 3.5rem;

                    span {
                        color: var(--text);
                        font-weight: bold;
                        font-size: 1.1rem;
                    }

                    .icon {
                        margin-left: auto;
                        transition: 250ms;
                    }
                }

                .questionsBody {
                    transition: 250ms;

                    p {
                        color: var(--gray-200);
                        text-align: justify;
                        line-height: 1.8rem;
                        width: 80%;
                    }
                }
            }
        }
    }

    .confirmationArea {     
        background: linear-gradient(20deg, #9358F7 0.87%, #9259F7 7.31%, #8E5DF6 13.75%, #8862F5 20.19%, #806BF4 26.63%, #7575F2 33.07%, #6882F0 39.51%, #5990EE 45.95%, #4A9FEB 52.39%, #3BADE9 58.84%, #2EBAE7 65.28%, #23C4E5 71.72%, #1BCDE4 78.16%, #15D2E3 84.6%, #11D6E2 91.04%, #10D7E2 97.48%);
        display: flex;
        align-items: center;
        padding: 2rem 3rem;

        .textArea {         
            width: 50%;

            h2 {            
                color: var(--white);
                font-size: 2rem;
            }

            p {             
                color: var(--white);
                line-height: 1.7rem;
                font-size: 1.2rem;
                margin-top: 1rem;
            }
        }

        .formWrap {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-left: auto;
        }
    }   // .confirmationAreaa

    h2 {
        line-height: 2.5rem;
    }   // h2
}   // .container


/** ---------------------- RESPONSIVIDADE MIN-WIDTH ---------------------- **/
@media (min-width: 780px) {
    .container {
        .examples {
            .sliderArea {
                .sliderInline {
                    .sliderItem {
                        .wrapInput {
                            margin-right: .5rem;
                        }   // .wrapInput
                    }   // .sliderItem
                }   // .sliderInline
            }   // .sliderArea
        }   // .examples
    }   // .container
}

@media(min-width: 880px) {
    .container {
        .examples {
            padding: 0 3rem;
        }
    }
}

@media(min-width: 1300px) {
    .container {
        .examples {
            .exampleArea {
                .exampleContainer {
                    .exampleGrid {
                        .exampleItem {
                            &:nth-child(n + 2) {
                                margin-left: 5rem;
                            }
                        }   // .exampleItem
                    }   // .exampleGrid
                }   // .exampleContainer
            }   // .exampleArea
        }   // .examples
    }   // .container
}

@media(min-width: 1700px) {
    .container {
        padding: 0 15rem;
    }
}

@media(min-width: 2000px) {
    .container {
        padding: 0 20rem;
    }
}

@media(min-width: 2200px) {
    .container {
        padding: 0 25rem;
    }
}

@media(min-width: 2500px) {
    .container {
        padding: 0 30rem;
    }
}
/** ----------------------- FIM MIN-WIDTH -------------------------------- **/


/** ---------------------- RESPONSIVIDADE MAX-WIDTH ---------------------- **/
@media(max-width: 479px) {
    .container {
        .content {
            flex-direction: column;
            align-items: center;
            padding: 0 1rem;

            .descriptiveTextArea {
                text-align: center;
                margin: 0;
                width: 100%;
                margin-top: 2rem;

                h2 {
                    font-size: 2rem;
                    line-height: 2.5rem;
                }

                p {
                    margin-top: 1rem;
                    line-height: 1.5rem;
                    font-size: 1.1rem;
                }
            }
        }

        .examples {
            padding: 0 1rem;

            .sliderArea {
                .sliderInline {
                    flex-direction: column;
                    justify-content: center;

                    .sliderItem {
                        flex-direction: column;
                        align-items: flex-start;
                        width: 100%;

                        &:nth-child(n + 2) {
                            margin-top: 2rem;
                        }

                        .wrapInput {
                            margin-top: 1rem;
                        }

                        .sliderVerticalItem {
                            margin-top: .7rem;
                        }
                    }
                }
            }

            .exampleArea {
                margin-top: 3rem;

                h2 {
                    text-align: center;
                }

                .exampleGrid {
                    flex-direction: column;

                    .exampleItem {
                        &:nth-child(n + 2) {
                            margin-top: 2rem;
                        }
                    }
                }
            }
        }

        h2 {
            line-height: 2.3rem;
        }
    }
}

@media(max-width: 635px) {
    .container {
        .questionsArea {
            .questionsInline {
                grid-template-columns: 1fr;
            }
        }
        
        .confirmationArea {
            flex-direction: column;
            align-items: center;
            width: 100%;

            .textArea {
                width: 100%;
                text-align: center;
            }

            .formWrap {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

@media(max-width: 770px) {
    .container {
        .about {
            margin-top: 1rem;
            padding: 0 1rem;

            h1 {
                text-align: center;
                font-size: 2.3rem;
                line-height: 2.8rem;
            }

            .aboutInline {
                flex-direction: column;
                padding: 0;

                .aboutItem {
                    padding: 2rem 0;

                    &:nth-child(n+2) {
                        border-top: 1px solid var(--gray-200);
                        border-left: 0;
                    }
                }
            }
        }

        .stepsArea {
            flex-direction: column;

            .stepsItem {
                &:nth-child(n + 2) {
                    margin: 2rem 0 0 0;
                }

                .text {
                    width: 15rem;
                    margin-left: 1rem;
                }
            }
        }
    }
}

@media (max-width: 879px) {
    .container {
        .content {
            padding: 1.5rem 1rem;
        }

        .stepsArea {
            padding: 0 1rem;
        }

        .examples {
            padding: 0 1rem;

            .sliderArea {
                h2 {
                    text-align: center;
                    line-height: 1.7rem;
                }

                .sliderInline {
                    .sliderItem {
                        width: 100%;

                        span {
                            font-size: 1.1rem;
                        }
                    }
                }
            }

            .exampleArea {
                margin-top: 4rem;

                h2 {
                    text-align: center;
                }
                
                .exampleContainer {
                    .exampleGrid {
                        .exampleItem {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            
                            &:nth-child(n + 2) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }

        .receitaArea {
            flex-direction: column-reverse;
            margin-top: 4rem;
            padding: 1.5rem 1rem;

            p {
                width: 100%;
                font-size: 1rem;
            }

            .lineSeparator {
                height: 1px;
                width: 100%;
                margin: 1rem 0;
            }

            .receitaValueArea {
                width: 100%;

                .inlineValue {
                    span {
                        &:first-child {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }

        .questionsArea {
            padding: 0 1rem;
        }

        .confirmationArea {
            padding: 1.5rem 1rem;
        }
    }
}

@media(max-width: 1030px) {
    .container {
        .examples {
            .exampleArea {
                .exampleContainer {
                    .exampleGrid {
                        .exampleItem {
                            .exampleHeader {
                                width: 100%;
                            }
        
                            .exampleFooter {
                                width: 100%;
                            }   // .exampleFooter
                        }   // .exampleItem
                    }   // .exampleGrid
                }   // .exampleContainer
            }   // .exampleArea
        }   // .examples
    }   // .container
}
/** ----------------------- FIM MAX-WIDTH ------------------------------- **/


/** ---------------------- RESPONSIVIDADE ENTRE ---------------------- **/
@media(min-width: 480px) and (max-width: 635px) {
    .container {
        .content {
            flex-direction: column;
            align-items: center;
            padding: 0 1rem;
    
            .descriptiveTextArea {
                text-align: center;
                margin: 0;
                width: 100%;
                margin-top: 2rem;
    
                h2 {
                    text-align: center;
                    font-size: 2rem;
                    line-height: 2.5rem;
                }
    
                p {
                    margin-top: 1rem;
                    line-height: 1.5rem;
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media (min-width: 480px) and (max-width: 779px) {
    .container {
        .examples {
            padding: 0 1rem;

            .sliderArea {
                .sliderInline {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;                }

                .sliderItem {
                    flex-direction: column;
                    align-items: flex-end;
                    width: 100%;

                    &:nth-child(n + 2) {
                        margin-top: 2rem;
                    }

                    .wrapInput {
                        align-self: flex-start;
                        margin-top: 1rem;
                    }

                    .sliderVerticalItem {
                        margin-top: .7rem;
                    }
                }
            }

            .exampleArea {
                .exampleContainer {
                    .exampleGrid {
                        display: grid;;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 2rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 780px) and (max-width: 978px) {
    .container {
        .examples {
            .sliderArea {
                .sliderInline {
                    gap: 1rem;

                    .sliderItem {
                        width: 100%;

                        .sliderVerticalItem {
                            width: 100%;
                        }   // .sliderVerticalItem
                    }   // .sliderItem
                }   // .sliderInline
            }   // .sliderArea
        }   // .examples
    }   // .container
}

@media(min-width: 780px) and (max-width: 1030px)  {
    .container {
        .examples {
            .exampleArea {
                .exampleContainer {
                    .exampleGrid {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 1.5rem;
    
                        .exampleItem {
                            &:nth-child(n + 2) {
                                margin-left: 0;
                            }
                        }   // .exampleItem
                    }   // .exampleGrid
                }   // .exampleContainer
            }   // .exampleArea
        }   // .examples
    }   // .container
}
/** ----------------------- FIM ENTRE ---------------------------- **/














