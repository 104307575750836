.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    padding: 0 3rem;

    .formArea {
        display: flex;
        justify-content: space-between;
        margin-top: 4rem;
        width: 100%;

        .examples {
            display: flex;
            flex-direction: column;

            .loaderComponent {
                display: flex;
                justify-content: center;
                margin-top: 2rem;
                min-height: 25rem;
            }

            .exampleContainer {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 2rem;
                margin-top: 1rem;

                .exampleItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
    
                    img {
                        margin-top: .5rem;
                        width: 126px;
                        height: 138px;
                    }
                }
            }

            .notFormatos {
                color: var(--gray-200);
                font-size: 1.1rem;
                margin-top: .5rem;
            }
        }

        .userDataArea {
            width: 30%;

            form {
                display: flex;
                flex-direction: column;
                margin-top: 1rem;
    
                .inputDiv {
                    input {
                        width: 13rem;
    
                        &:nth-child(n + 2) {
                            margin-top: .5rem;
                        }
                    }
                }
    
                .inlineInput {
                    display: flex;
                    flex-direction: column;
                    margin-top: .7rem;
                    
                    .inputDiv {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-top: .5rem;

                        .cepText {
                            color: blue;
                            cursor: pointer;
                            margin-left: .5rem;
                            width: 8rem;
        
                            &:hover {
                                text-decoration: underline;
                            }
                            
                        }
                    }
                }
    
                .checkboxArea {
                    display: flex;
                    margin: 2rem 0 0 -.8rem;
    
                    span {
                        font-size: 1rem;
                        width: 75%;
                    }
                }
            }
        }

        .inlinePreviewAndForm {
            display: flex;
            justify-content: space-between;
            width: 70%;

            .previewArea {
                display: flex;
                justify-content: flex-end;
                margin-top: 3rem;
                width: 100%;
    
                .preview {
                    background-color: var(--gray-50);
                    width: 90%;
                    height: 20rem;
                    border-radius: .6rem;
    
                    .topBar {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 4rem;
                        padding: 0 1rem;
                        border-top-left-radius: .5rem;
                        border-top-right-radius: .5rem;
    
                        .logoPlaceholder {
                            background-color: var(--secondary);
                            width: 6rem;
                            height: 2rem;
                            border-radius: .2rem;
                        }
    
                        .right {
                            display: flex;
                            align-items: center;
                            margin-left: auto;
    
                            .colorText {
                                width: 2rem;
                                height: 2rem;
                                border-radius: 1rem;
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }
    
            .siteInfoArea {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 90%;
            }
        }   // .inlinePreviewAndForm
    }   // .formArea

    .previewSites {
        .inlineSites {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 1rem;
            margin-top: 1rem;
    
            .previewItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
    
                &:hover {
                    .logoContainer {
                        box-shadow: 0px 0px 1rem 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
                    }
                }
    
                .logoContainer {
                    transition: 250ms;
                    border-radius: 1.2rem;
    
                    .logoArea {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 5rem;
                        height: 5rem;
                        border-radius: 1rem;
            
                        span {
                            color: var(--white);
                            text-transform: uppercase;
                            font-weight: bold;
                            font-size: 1.5rem;
                        }
    
                        .iconLogo {
                            width: 2rem;
                            height: 2rem;
                            border-radius: 50px;
                            clip-path: circle(32px at center);
                            overflow: hidden;
                            margin:2px;

                            img {
                                width: 2rem;
                                height: 2rem;
                            }
                        }
                    }            
                }
        
                .nameWrapper {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 5rem;
                    margin-top: .3rem;
                    
                    .name {
                        color: var(--text);
                        font-weight: bold;
                        font-size: 1rem;
                    }   // .name
                }
            }   //. previewItem
        }   // .inlineSites
    }   // .previewSites

    .confirmationArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem;
        border-top: 1px solid var(--gray-100);
        width: 100%;
        padding: 2rem 0;

        .alignLeft {
            span {
                color: var(--text);
                font-size: 1rem;
            }

            .inlineValue {
                display: flex;
                align-items: center;

                span {
                    
                    &:first-child {
                        font-size: 2rem;
                    }

                    &:last-child {
                        margin-left: .6rem;
                    }
                }   // span
            }   // .inlineValue
        }   // .alignLeft
    }   // .confirmationArea
}   // .container

.inputDiv {
    display: flex;
    flex-direction: column;
    margin-top: .7rem;
}

/** ---------------------- RESPONSIVIDADE MIN-WIDTH ---------------------- **/
@media(min-width: 1700px) {
    .container {
        padding: 0 15rem;
    }
}

@media(min-width: 2000px) {
    .container {
        padding: 0 20rem;
    }
}

@media(min-width: 2200px) {
    .container {
        padding: 0 25rem;
    }
}

@media(min-width: 2500px) {
    .container {
        padding: 0 30rem;
    }
}
/** ----------------------- FIM MIN-WIDTH -------------------------------- **/


/** ---------------------- RESPONSIVIDADE MAX-WIDTH ---------------------- **/
@media (max-width: 479px) {
    .container {
        margin-top: 3rem;

        .formArea {
            .userDataArea {
                width: 100%;
            }
        }

        .previewSites {
            .inlineSites {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 1rem;

                .previewItem {
                    &:nth-child(n + 2) {
                        margin-left: 0;
                    }
                }
            }
        }

        .confirmationArea {
            flex-direction: column;
            align-items: flex-start;
            padding: 2rem 0;

            .alignLeft {
                align-items: flex-end;
                display: flex;
                flex-direction: column;
                width: 100%;

                .inlineValue {
                    span {
                        &:first-child {
                            font-size: 1.8rem;
                        }
                    }
                }
            }

            .buttonArea {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-top: 3rem;
            }
        }
    }
}

@media (max-width: 650px) {
    .container {
        .previewSites {
            .inlineSites {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-gap: 1rem;
    
                .previewItem {
                    &:nth-child(n + 2) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: 880px) {
    .container {
        padding: 0 1rem;

        .formArea {
            width: 100%;
            flex-direction: column;

            .userDataArea {
                width: 100%;
            }

            .inlinePreviewAndForm {
                flex-direction: column;
                width: 100%;

                .previewArea {
                    justify-content: flex-start;

                    .preview {
                        width: 100%;
                    }
                }

                .siteInfoArea {
                    align-items: flex-start;
                    width: 100%;
                    margin-top: 3rem;

                    .inlineButton {
                        display: flex;
                        justify-content: flex-end;
                        width: 100%;
                    }
                }
            }
        }

        .previewSites {
            .inlineSites {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
}
/** ----------------------- FIM MAX-WIDTH -------------------------------- **/


/** ---------------------- RESPONSIVIDADE ENTRE ---------------------- **/
@media(min-width: 450px) and (max-width: 599px) {
    .container {
        .formArea {
            .examples {
                .exampleContainer {
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
    }
}

@media(min-width: 600px) and (max-width: 749px) {
    .container {
        .formArea {
            .examples {
                .exampleContainer {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}

@media(min-width: 750px) and (max-width: 1099px) {
    .container {
        .formArea {
            .examples {
                .exampleContainer {
                    grid-template-columns: repeat(5, 1fr);
                }
            }
        }
    }
}

@media(min-width: 881px) and (max-width: 1099px) {
    .container {
        padding: 0 1rem;

        .formArea {
            flex-direction: column;

            .userDataArea {
                width: 100%;
            }

            .inlinePreviewAndForm {
                width: 100%;
                margin-top: 2rem;

                .previewArea {
                    justify-content: flex-start;

                    .preview {
                        width: 90%;
                    }
                }

                .siteInfoArea {
                    align-items: flex-end;
                    width: 50%;
                }
            }
        }
    }
}

@media(min-width: 1100px) and (max-width: 1400px) {
    .container {
        .previewSites {
            .inlineSites {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
/** ----------------------- FIM ENTRE -------------------------------- **/
