.container {
    margin-top: 4rem;
    padding: 1rem 3rem;

    .contentArea {
        display: flex;
        justify-content: space-between;
        margin-top: 5rem;

        section {
            width: 45%;

            p {
                color: var(--gray-900);
                line-height: 1.7rem;
            }

            .confirmData {
                h2 {
                    line-height: 2.1rem;
                    margin-bottom: .5rem;
                }
            }

            .adManagerArea {
                padding-bottom: 1rem;

                .verifySiteArea {
                    margin-top: 2rem;
    
                    .siteItem {
                        &:nth-child(n + 2) {
                            margin-top: 2rem;
                        }
    
                        span {
                            color: var(--text);
                            font-weight: bold;
                            font-size: 1rem;
                        }
    
                        .inlineResponse {
                            display: flex;
                            align-items: center;
                            margin-top: .7rem;
    
                            .verifyResponse {
                                background-color: var(--gray-100);
                                display: flex;
                                width: 50%;
                                height: 6rem;
                                border-radius: .5rem;
    
                                &:last-child {
                                    margin-left: 3rem;
                                }
    
                                .situation {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 30%;
                                    border-right: 1px solid var(--gray-200);
                                }
    
                                .title {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 70%;
    
                                    h2 {
                                        font-size: 1.7rem;
                                        font-weight: bold;
                                    }   // h2
                                }   // .title
                            }   // .verifyResponse
                        }   // .inlineResponse
                    }   // siteItem
                }   // .verifySiteArea
            }   // .adManagerArea
        }   // section
    }   // .contentArea

    .nextPageButton {
        display: flex;
        justify-content: flex-end;
        margin: 4rem 0 2rem 0;
    }   // .nextPageButton
}   // .container


/** ---------------------- RESPONSIVIDADE MIN-WIDTH ---------------------- **/
@media(min-width: 1700px) {
    .container {
        padding: 0 15rem;
    }
}

@media(min-width: 2000px) {
    .container {
        padding: 0 20rem;
    }
}

@media(min-width: 2200px) {
    .container {
        padding: 0 25rem;
    }
}

@media(min-width: 2500px) {
    .container {
        padding: 0 30rem;
    }
}
/** ----------------------- FIM MIN-WIDTH -------------------------------- **/


/** ---------------------- RESPONSIVIDADE MAX-WIDTH ---------------------- **/
@media (max-width: 479px) {
    .container {
        .contentArea {
            flex-direction: column;
            margin-top: 3rem;

            section {
                width: 100%;
                
                p {
                    font-size: 1.1rem;
                    margin-top: 1rem;
                    text-align: center;
                }

                .confirmData {
                    h2 {
                        text-align: center;
                        line-height: 2.1rem;
                    }
                }

                .adManagerArea {
                    margin-top: 2rem;

                    h2 { 
                        text-align: center;
                    }

                    .verifySiteArea {
                        .siteItem {
                            &:nth-child(n + 2) {
                                margin-top: 2rem;
                            }
                            
                            .inlineResponse {
                                flex-direction: column;

                                .verifyResponse {
                                    width: 100%;
                                    height: 4.5rem;

                                    &:last-child {
                                        margin-left: 0;
                                        margin-top: 1rem;
                                    }

                                    .title {
                                        h2 {
                                            font-size: 1.4rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .nextPageButton {
            padding: 0;
        }
    }
}

@media(max-width: 850px) {
    .container {
        padding: 1rem;
        margin-top: 3rem;

        .contentArea {
            flex-direction: column;
            margin-top: 3rem;

            section {
                width: 100%;
                
                .adManagerArea {
                    margin-top: 3rem;
                }
            }
        }
    }
}
/** ----------------------- FIM MAX-WIDTH -------------------------------- **/


/** ---------------------- RESPONSIVIDADE ENTRE ---------------------- **/
@media(min-width: 851px) and (max-width: 1150px) {
    .container {
        .contentArea {
            section {
                width: 47%;
            }
        }
    }
}
/** ----------------------- FIM ENTRE -------------------------------- **/
